var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-tabs",
        { key: _vm.tabsCorporateSalesKey },
        [
          _c(
            "vs-tab",
            { attrs: { label: _vm.$t("marketing_place.purchase_summary") } },
            [
              _c("CartResume", { attrs: { cartItems: _vm.cart.items } }),
              _c(
                "div",
                { staticClass: "w-full flex gap-2 justify-end" },
                [
                  _c(
                    "vs-button",
                    { attrs: { type: "border" }, on: { click: _vm.goBack } },
                    [_vm._v(" " + _vm._s(_vm.$t("common.back")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }